<script lang="ts">
  import { cn } from '@utils/utils';

  let className: string;
  export { className as class };
</script>

<svg
  {...$$restProps}
  width="40"
  height="41"
  class={cn('', className)}
  viewBox="0 0 40 41"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M20 40.7422C31.0457 40.7422 40 31.7879 40 20.7422C40 9.69649 31.0457 0.742188 20 0.742188C8.9543 0.742188 0 9.69649 0 20.7422C0 31.7879 8.9543 40.7422 20 40.7422ZM28.389 15.4944C28.9931 14.6326 28.7842 13.4444 27.9224 12.8403C27.0607 12.2363 25.8724 12.4451 25.2684 13.3069L20.1392 20.6239L17.7896 23.9759C17.4957 24.3952 16.8519 24.3122 16.674 23.8321L15.2007 19.858C14.8349 18.8713 13.7385 18.3679 12.7517 18.7337C11.765 19.0995 11.2616 20.1959 11.6274 21.1827L13.1006 25.1567C14.3465 28.5175 18.8529 29.0983 20.9102 26.1634L23.2599 22.8114L28.389 15.4944Z"
  />
</svg>

<script lang="ts">
  import { cn } from '@utils/utils';
  import { Check, Circle, Plus } from 'lucide-svelte';

  import { CdnImage, ExtensionType } from '@components/Images/CdnImage';
  import Button from '@components/ui/button/button.svelte';
  import * as Carousel from '@components/ui/carousel';
  import type { CarouselAPI } from '@components/ui/carousel/context';
  import { RichFeatureSection } from '@components/ui/rich-feature-section';
  import { SectionWrapper } from '@components/ui/section-wrapper';
  import { Typography } from '@components/ui/typography';
  import { ApplyNowButton } from '@pages/_components/ApplyNowButton';

  import BentoCard from './BentoCard.svelte';

  let api: CarouselAPI;

  let currentIndex = 0;

  const handleClick = (index: number) => {
    currentIndex = index;
    api.scrollTo(index);
  };

  $: if (api) {
    api.on('select', () => {
      currentIndex = api.selectedScrollSnap();
    });
  }
</script>

<SectionWrapper id="unlock-access-to-a-community">
  <RichFeatureSection class="items-start gap-y-8">
    <CdnImage
      class="col-span-12 md:col-span-5 md:mr-10 order-2 md:order-1"
      alt="Your beauty guru"
      load="lazy"
      src="magic_os_lp_march_2024/improved/phone_mockup_post_smiled_man"
      width={270}
      height={480}
      baseExt={ExtensionType.png}
    />

    <article
      class="col-span-12 w-full md:col-span-6 flex flex-col items-start gap-8 md:col-start-7 order-1 md:order-2"
      style="--foreground: 173 72% 18%"
    >
      <Typography variant="h2" size="7xl" class="text-balance">
        Unlock access to a community
      </Typography>

      <Carousel.Root
        bind:api
        opts={{
          align: 'start'
        }}
        class="overflow-hidden w-full"
        style="--foreground:204 59% 89%;"
      >
        <Carousel.Content>
          <Carousel.Item>
            <BentoCard
              title="For brands"
              description="Brands gain access to a real community of shoppers and creators, increasing visibility,
              and leveraging user-generated content to drive sales"
            >
              <Check class="stroke-primary" />
            </BentoCard>
          </Carousel.Item>
          <Carousel.Item>
            <BentoCard
              title="For shoppers"
              description="Users discover and engage with brands through authentic video reviews and a seamless
              shopping experience from discovery to checkout"
            >
              <Plus class="stroke-primary" />
            </BentoCard>
          </Carousel.Item>
          <Carousel.Item>
            <BentoCard
              title="For creators"
              description="Shoppers turn into creators by posting video reviews, earning rewards and cash, and
              engaging with the community. 100% of Flip creators are shoppers first who can create a
              review only once they receive their purchased product"
            >
              <Circle class="stroke-primary fill-primary" />
            </BentoCard>
          </Carousel.Item>
        </Carousel.Content>
        <div class="flex items-center gap-2 mt-4">
          {#each Array.from({ length: 3 }) as _, index}
            <Button
              class={cn('w-3 h-3 p-0', {
                'bg-primary/40': currentIndex !== index
              })}
              variant="default"
              on:click={() => handleClick(index)}
            />
          {/each}
        </div>
      </Carousel.Root>

      <ApplyNowButton class="w-full md:w-auto" />
    </article>
  </RichFeatureSection>
</SectionWrapper>

<style lang="postcss" global>
  :root #unlock-access-to-a-community {
    --background: 204 59% 89%;
    --primary: 173 72% 18%;
    --primary-foreground: 204 59% 89%;
  }

  :root #unlock-access-to-a-community [data-button-root] {
    --primary: 173 72% 18%;
    --primary-foreground: 204 59% 89%;
    --background: 173 72% 18%;
    --foreground: 204 59% 89%;
  }
</style>

<script lang="ts">
  import CheckLp from '@components/SVGs/CheckLP.svelte';
  import { Card } from '@components/ui/bento';

  import { type BentoCardProps } from '.';

  type $$Props = BentoCardProps;

  export let title: $$Props['title'];
  export let description: $$Props['description'];
</script>

<Card let:Title let:Description class="rounded-3xl p-6 lg:p-8 shrink-0 md:w-full" {...$$restProps}>
  <div
    slot="icon"
    style="--background: 204 59% 89%; --foreground: 173 72% 18%;"
    class="size-10 md:size-12 rounded-full flex items-center justify-center"
  >
    <CheckLp class="fill-background bg-foreground" />
  </div>
  <Title as="h3">
    {title}
  </Title>
  <Description class="text-sm leading-normal font-normal">
    {description}
  </Description>
</Card>
